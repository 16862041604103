import img from "../images/index"

const developData = [
  {
    time: "Pre-2021",
    title: "INIT",
    content: [
      "深度探索 React、React Native、TypeScript 后将它们作为技术基石",
      "依赖 React 生态，使用 CRA、Antd、dva 等工具和库创建脚手架，树立代码管理、编码风格等规范",
      "借助 Jenkins、Fastlane 等工具完成 CI/CD 工作流程搭建，节省测试阶段打包时间并且降低研发成本",
      "搭建招聘体系，产出培训手册，梳理面试流程，打造团队体系",
    ],
  },
  {
    time: "2021-05",
    title: "Change",
    content: [
      "随着 React 状态管理库推陈出新，深度探索 Zustand 和 Jotai 后选择 Zustand 逐步替代 dva",
      "通过 Scrum Sprints 落地敏捷开发原则",
      "GraphQL + gRPC 替代 Swagger + RESTful API 应用架构，推动 GraphQL 在前端基建中落地",
      "主导部分 Native（Android、iOS） SDK 桥接到 React Native 环境，推进 Hermes 在项目中广泛使用",
      "使用 Vite 替换 Webpack 搭建前端开发环境，为 web 项目开发构建提速",
    ],
  },
  {
    time: "2021-07",
    title: "Xiaoshu（小暑）",
    content: [
      "深度调研 Next.js、Gatsby.js，最终选择 Gatsby.js 作为前端 wiki 知识库培训站点的基础框架",
      "React Native Xiaoshu （小暑）提供开箱即用的高质量 React Native 组件，支持灵活的样式定制，简易生成多种风格，满足个性化产品需求",
    ],
    url: "https://hjfruit.github.io/xiaoshu-doc/",
    img: img.xiaoShu,
  },
  {
    time: "2021-10",
    title: "微前端、知识库 blog",
    content: [
      "在业务中首次运用基于 single-spa 的 qiankun 微前端方案，并在实践过程中进行了诸多升级优化",
      "引入 Next.js 代替原有的多页架构（落地页）方案 （Node.js）",
    ],
  },
  {
    time: "2021-12",
    title: "白露",
    content: [
      "React Bailu（白露，基于 Ant Design 的 web 端业务 UI 组件库）的诞生，帮助业务开发速度大幅提升",
      "实现 OAuth2 单点登录",
    ],
    url: "https://hjfruit.github.io/bailu-doc/",
  },
  {
    time: "2022-01",
    title: "腊八&进化",
    content: [
      "腊八 hooks 工具库满足当前业务的 React Native App 开发，让开发人员使用起来更加高效和便利",
      "提出应用交付工程师的全栈计划，实现 Nest.js、Prisma ORM、Apollo Server、Koa 等技术储备",
    ],
    img: img.laba,
    url: "https://hjfruit.github.io/laba-doc/",
  },
  {
    time: "2022-04",
    title: "秋分",
    content: [
      "GraphQL 开发工具，提供接口文档、数据 Mock、查询语句一键生成、API 在线调试等功能，对前后端联调效率有极大提升",
    ],
    url: "/qiufen",
  },
  {
    time: "2022-06",
    title: "图标库&Map&数据可视化",
    content: [
      "定制公司 UI 风格的 SVG 图标库，适用于 web、React Native 项目",
      "GIS 海外地图项目技术栈切换至 Mapbox + AntV，探索 GIS 可视化上更多的可能性",
      "支援数据团队，沉淀了 X6、XFlow、L7 等库的应用技术，基于 AntV 沉淀交互可视化开发的经验",
    ],
    img: img.icons,
    url: "https://hjfruit.github.io/icon-doc/",
  },
  {
    time: "2022-08",
    title: "utils库&Native",
    content: [
      "发布了 JS/TS 函数工具库，提高项目可维护性，减少代码冗余",
      "华为 OBS 上传、个推一键登录、微信 Native SDK 桥接到 React Native 环境，团队原生桥接能力进步飞快",
      "使用萤石云 API、SDK 获取监控设备信息，实现监控云台控制功能",
    ],
    url: "https://hjfruit.github.io/utils/#/",
    img: img.utils,
  },
  {
    time: "2022-10",
    title: "打包平台 & 多语言管理平台",
    content: [
      "前端全栈技术相关成果集中亮相，打包平台使用 Nest.js + Prisma + GraphQL 等技术，多语言管理平台使用 Next.js + Prisma + Nexus + Apollo Server 等技术",
      "打通 DevOps CI/CD Pipeline 流程，打包平台链接运维服务",
    ],
  },
  {
    time: "2022-12",
    title: "大寒",
    content: [
      "前端业务效率工具 + 最佳实践代码片段帮助 web 项目一名前端支撑整个业务开发",
    ],
    img: img.dahan,
  },
  {
    time: "2023",
    title: "One More Thing",
    content: [
      "完成基于 React、Antd、白露的低代码渲染组件，以拟定的 Schema JSON 描述性协议为根本，渲染业务表单和表格，无需开发者重复编写表单、表格的渲染逻辑，方便类似业务的快速迭代",
      "Chunfen（春分），基于 React 生态的高可扩展的 web 前端框架，路由、状态管理、权限、SSR 等功能通过插件配置，开箱即用，并提供 IDE 插件让你方便快捷地完成项目搭建、业务开发、项目构建",
      "功能模块插件化高度复用，业务模块动态化灵活部署，「拼凑积木」的方式组合出一个超级 App",
      "监控平台的开发和前端测试的推进",
    ],
    img: img.oneMoreThing,
  },
]

export default developData
