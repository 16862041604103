import * as React from "react"
import Head from "../components/head/index"
import PixiBackgroundComponent from "../components/pixiBackground/index"
import Seo from "../components/seo"
import * as styles from "../styles/index.module.less"
import classnames from "classnames"
import { useState, useEffect } from "react"
import developData from "../milestones-data/index"
import Link from "../utils/link"
import img from "../images/index"
import IntroItem from "../components/introItem"

const MainPage = ({ location }) => {
  // 导航栏隐藏或显示
  const [navStatus, setNavStatus] = useState({
    hidden: false,
    bgTransparent: true,
  })

  // 明暗主题状态
  const [darkStatus, setDarkStatus] = useState(true)

  // 汉堡按钮 > 抽屉导航栏
  const [drawerActive, setDrawerActive] = useState(false)

  useEffect(() => {
    let scroll
    import("locomotive-scroll").then(locomotiveModule => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      })

      let oldTop = 0 //旧数据
      scroll.on("scroll", instance => {
        let scrollTop = instance.scroll.y
        if (scrollTop === 0) {
          // 滚动到顶部，导航背景透明
          setNavStatus({ bgTransparent: true, hidden: false })
        } else if (scrollTop > oldTop) {
          // 滚动条向下，导航栏隐藏
          setNavStatus({ bgTransparent: false, hidden: true })
        } else {
          // 滚动条向上，导航栏显示
          setNavStatus({ bgTransparent: false, hidden: false })
        }
        oldTop = scrollTop //更新旧位置
      })
    })
    return () => scroll.destroy()
  }, [])

  // 设置视频播放结束 回到初始状态
  useEffect(() => {
    var video1 = document.getElementById("video1")
    var video2 = document.getElementById("video2")
    const videoArr = [video1, video2]
    videoArr.forEach(item => {
      item.onended = function () {
        const videoSrc = item.currentSrc
        item.src = ""
        item.src = videoSrc
      }
    })
  }, [])

  return (
    <div>
      <Seo title="洪九果品 前端团队" />

      {/* 头部导航栏 */}
      <Head
        path={location.pathname}
        value={{ navStatus, darkStatus, drawerActive }}
        setDarkStatus={setDarkStatus}
        setDrawerActive={setDrawerActive}
      />
      <div
        className={classnames({
          [`${styles.outer}`]: true,
          [`${styles.dark}`]: darkStatus,
          [`${styles.drawer}`]: drawerActive,
        })}
      >
        {/* 动态背景 */}
        <PixiBackgroundComponent />

        <div className={styles.pageContent} data-scroll-container>
          <div className={styles.inner}>
            <div className={styles.content}>
              {/* 团队描述 */}
              <div className={styles.contentDev}>
                {/* development history图片展示 */}
                <img
                  data-scroll
                  data-scroll-speed="2"
                  src={
                    darkStatus
                      ? img.developmentDarkImg
                      : img.developmentLightImg
                  }
                  alt=""
                  className={styles.devImg}
                />

                {/* 团队简介 */}
                <h2 data-scroll data-scroll-speed="3">
                  发展历程
                </h2>
                <p className={styles.desc} data-scroll data-scroll-speed="4">
                  Everything's Eventual
                  前端团队隶属于洪九果品（06689）信息中心，现有15位成员。经过时间的沉淀，我们已经建立了完备的技术体系并打造了多个服务于业务的工具，平时也热衷于开源与分享，会不定期举行技术分享。在这里呼唤更多的小伙伴加入我们的团队，海阔天空，凭君鱼跃，任你高飞。
                </p>

                {/* 点击查看更多定位到 里程碑描述开始位置 */}
                <div
                  className={styles.moreButton}
                  href="#introStart"
                  data-scroll-to
                >
                  查看更多
                  <img
                    className={styles.arrow4}
                    src={darkStatus ? img.arrow4 : img.arrow3}
                    alt=""
                  />
                </div>

                {/* 鼠标图标 */}
                <div className={styles.mouseIcon}>
                  <div className={styles.smallBar}></div>
                  <p className={styles.scroll}>Scroll</p>
                </div>

                {/* JS TS 玻璃块 */}
                <img
                  src={darkStatus ? img.jsDark : img.jsImg}
                  alt=""
                  className={styles.jsImg}
                  data-scroll
                  data-scroll-speed="2"
                />
                <img
                  src={darkStatus ? img.tsDark : img.tsImg}
                  alt=""
                  className={styles.tsImg}
                  data-scroll
                  data-scroll-speed="6"
                />

                <div className={styles.wrapVideo}>
                  <video controls className={styles.video} id="video2">
                    <source
                      src="https://hjfruit-2.hjgpscm.com/videos/video2023022401.mp4"
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </div>

              {/* 团队视频介绍 */}
              <div className={styles.intro} id="intro">
                <div
                  className={styles.introLeft}
                  data-scroll
                  data-scroll-sticky
                  data-scroll-target="#intro"
                >
                  <div className={styles.purpleBar} />
                  {/* <div className={styles.purpleBarBg} /> */}
                  <p>About</p>
                  <img src={img.usText} alt="" />
                  <p>了解我们</p>
                  <img src={img.purpleArrow} alt="" />
                  {/* <div className={styles.purpleArrowBg} /> */}
                </div>
                <div
                  className={styles.introRight}
                  data-scroll
                  data-scroll-sticky
                  data-scroll-target="#intro"
                >
                  <div className={styles.wrapVideo}>
                    <video controls className={styles.video} id="video1">
                      <source
                        src="https://hjfruit-2.hjgpscm.com/videos/video2023022401.mp4"
                        type="video/mp4"
                      ></source>
                    </video>
                  </div>
                </div>
              </div>

              {/* 定位锚点 */}
              <div id="introStart" className={styles.introStart} />

              {/* 里程碑内容 */}
              {developData.map((item, index) => {
                if (index === 1 || index === 4) {
                  return (
                    <div
                      key={index}
                      className={[
                        `${styles.contentTow}`,
                        `${styles.contentCommon}`,
                        `${styles.contentTowApp}`,
                      ].join(" ")}
                    >
                      <div className={styles.order}>
                        <div className={styles.bar}>
                          <img src={img.pinkBar} alt="" />
                          <div className={styles.round}>{index + 1}</div>
                        </div>
                      </div>
                      <div className={styles.info}>
                        <div className={styles.infoLeft}>
                          {index === 1 && (
                            <>
                              <img
                                className={styles.codeCard1}
                                src={darkStatus ? img.codeCard1 : img.codeCard6}
                                alt=""
                                data-scroll
                                data-scroll-direction="horizontal"
                                data-scroll-position="left"
                                data-scroll-speed="3"
                              />
                              <img
                                className={styles.codeCard2}
                                src={darkStatus ? img.codeCard2 : img.codeCard5}
                                alt=""
                                data-scroll
                                data-scroll-direction="horizontal"
                                data-scroll-position="left"
                                data-scroll-speed="2"
                              />
                            </>
                          )}
                          {index === 4 && (
                            <>
                              <img
                                className={styles.codeCard3}
                                src={darkStatus ? img.codeCard3 : img.codeCard8}
                                alt=""
                                data-scroll
                                data-scroll-direction="horizontal"
                                data-scroll-position="left"
                                data-scroll-speed="2"
                              />
                              <img
                                className={styles.codeCard4}
                                src={darkStatus ? img.codeCard4 : img.codeCard7}
                                alt=""
                                data-scroll
                                data-scroll-direction="horizontal"
                                data-scroll-position="left"
                                data-scroll-speed="3"
                              />
                            </>
                          )}
                        </div>
                        <div className={styles.infoRight}>
                          <div className={styles.time}>{item.time}</div>
                          <div className={styles.title}>{item.title}</div>
                          <div className={styles.desc}>
                            {item.content.map((textItem, index) => {
                              return (
                                <div className={styles.descItem} key={index}>
                                  <img src={img.pinkArrow} alt="" />
                                  <p className={styles.fw500}>{textItem}</p>
                                </div>
                              )
                            })}
                          </div>
                          {item.img && (
                            <div
                              className={styles.img}
                              style={{ backgroundImage: `url(${item.img})` }}
                            ></div>
                          )}
                          {item.url && (
                            <Link to={item.url} itemProp="url" target="_blank">
                              <div className={styles.more}>
                                <span>查看更多</span>
                                <div className={styles.circle}>
                                  <img
                                    src={img.arrowSmall}
                                    alt=""
                                    className={styles.arrowSmall}
                                  />
                                </div>
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }

                return (
                  <IntroItem
                    itemData={item}
                    darkStatus={darkStatus}
                    index={index}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPage
