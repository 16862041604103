import * as React from "react"

import * as styles from "./index.module.less"
import { Application, Sprite, BLEND_MODES, Rectangle, Assets } from "pixi.js"
const CIRCLE_SPACE = 300
// const COLOR_ARRAY = ["0xFF414E", "0x497AFF", "0x935DFF", "0xFFD30A"]
const COLOR_ARRAY = ["blue", "purple", "red", "yellow"]
const circleArray = []

const bgAnimation = element => {
  const width = element?.offsetWidth || 0
  const height = element?.offsetHeight || 0

  const app = new Application({
    backgroundAlpha: 0,
    width: width,
    height: height,
    resizeTo: element,
  })
  const boxBoundsPadding = 350
  const boxBounds = new Rectangle(
    -boxBoundsPadding,
    -boxBoundsPadding,
    app.screen.width + boxBoundsPadding * 2,
    app.screen.height + boxBoundsPadding * 2
  )
  Assets.add("blue", "./images/blue.png")
  Assets.add("purple", "./images/purple.png")
  Assets.add("red", "./images/red.png")
  Assets.add("yellow", "./images/yellow.png")
  const texturesPromise = Assets.load(["blue", "purple", "red", "yellow"])
  texturesPromise.then(textures => {
    element.appendChild(app.view)
    // 生成圆
    const circleNum = 3
    for (let i = 0; i < circleNum; i++) {
      const circle = Sprite.from(textures[COLOR_ARRAY[i % COLOR_ARRAY?.length]])
      circle.anchor.set(0.4)
      circle.x = Math.floor(Math.random() * app.screen.width)
      circle.y = Math.floor(Math.random() * i * CIRCLE_SPACE)
      circle.scale.set(0.7 + Math.random() * 0.3)
      circle.blendMode = BLEND_MODES.ADD
      circle.direction = Math.random() * Math.PI * 1
      circle.turningSpeed = Math.random() - 0.8
      circle.speed = 2 + Math.random() * 2

      circleArray.push(circle)
      app.stage.addChild(circle)
    }
  })

  app.ticker.add(() => {
    for (let i = 0; i < circleArray.length; i++) {
      const circleItem = circleArray[i]
      circleItem.direction += circleItem.turningSpeed * 0.01
      circleItem.x += Math.sin(circleItem.direction) * circleItem.speed
      circleItem.y += Math.cos(circleItem.direction) * circleItem.speed

      // wrap the dudes by testing their bounds...
      if (circleItem.x < boxBounds.x) {
        circleItem.x += boxBounds.width
      } else if (circleItem.x > boxBounds.x + boxBounds.width) {
        circleItem.x -= boxBounds.width
      }

      if (circleItem.y < boxBounds.y) {
        circleItem.y += boxBounds.height
      } else if (circleItem.y > boxBounds.y + boxBounds.height) {
        circleItem.y -= boxBounds.height
      }
    }
  })
}
const PixiBackgroundComponent = () => {
  const ref = React.useRef(null)
  const animationStart = React.useCallback(() => {
    bgAnimation(ref.current)
  }, [ref.current])
  React.useEffect(() => {
    animationStart()
  }, [])
  return <div className={styles.background} ref={ref} />
}
export default PixiBackgroundComponent
