import * as styles from "../introItem/index.module.less"
import img from "../../images/index"
import * as React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
const IntroItem = ({ itemData, darkStatus, index }) => {
  // item项布局类型
  const itemType = {
    0: "left",
    1: "center",
    2: "right",
  }[`${(index + 1) % 3}`]

  switch (itemType) {
    case "left":
      return (
        <div
          key={index}
          className={classnames({
            [`${styles.contentOdd}`]: true,
            [`${styles.contentCommon}`]: true,
            [`${styles.contentDark}`]: darkStatus,
          })}
        >
          <div className={styles.order}>
            <div className={styles.bar}>
              <img src={img.roundBar} alt="" />
              <div className={styles.round}>{index + 1}</div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoLeft}>
              <div className={styles.time}>{itemData.time}</div>
              <div className={styles.title}>{itemData.title}</div>
              <div className={styles.desc}>
                {itemData.content.map((textItem, index) => {
                  return (
                    <div className={styles.descItem} key={index}>
                      <img src={img.arrow2} alt="" />
                      <p className={styles.fw500}>{textItem}</p>
                    </div>
                  )
                })}
              </div>
              <div
                className={styles.img}
                style={{ backgroundImage: `url(${itemData.img})` }}
              ></div>
              {itemData.url && (
                <Link to={itemData.url} itemProp="url" target="_blank">
                  <div className={styles.more}>
                    <span>查看更多</span>
                    <div className={styles.circle}>
                      <img
                        src={img.arrowSmall}
                        alt=""
                        className={styles.arrowSmall}
                      />
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <div className={styles.infoRight}>
              <div
                className={styles.img}
                style={{ backgroundImage: `url(${itemData.img})` }}
              ></div>
            </div>
          </div>
        </div>
      )
    case "center":
      return (
        <div
          key={index}
          className={classnames({
            [`${styles.contentEven}`]: true,
            [`${styles.contentCommon}`]: true,
            [`${styles.contentDark}`]: darkStatus,
          })}
        >
          <div className={styles.order}>
            <div className={styles.bar}></div>
            <div className={styles.round}>{index + 1}</div>
          </div>
          <div className={styles.time}>{itemData.time}</div>
          <div className={styles.title}>{itemData.title}</div>
          <div className={styles.desc}>
            {itemData.content.map((textItem, index) => {
              return (
                <div className={styles.descItem} key={index}>
                  <img src={img.arrow1} alt="" />
                  <p className={styles.fw500}>{textItem}</p>
                </div>
              )
            })}
          </div>
          {itemData.url && (
            <Link to={itemData.url} itemProp="url" target="_blank">
              <div className={styles.more}>
                <span>查看更多</span>
                <div className={styles.circle}>
                  <img
                    src={img.arrowSmall}
                    alt=""
                    className={styles.arrowSmall}
                  />
                </div>
              </div>
            </Link>
          )}
        </div>
      )
    case "right":
      return (
        <div
          key={index}
          className={classnames({
            [`${styles.contentTow}`]: true,
            [`${styles.contentCommon}`]: true,
            [`${styles.contentDark}`]: darkStatus,
          })}
        >
          <div className={styles.order}>
            <div className={styles.bar}>
              <img src={img.pinkBar} alt="" />
              <div className={styles.round}>{index + 1}</div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoLeft}>
              <div
                className={styles.img}
                style={{ backgroundImage: `url(${itemData.img})` }}
              ></div>
            </div>
            <div className={styles.infoRight}>
              <div className={styles.time}>{itemData.time}</div>
              <div className={styles.title}>{itemData.title}</div>
              <div className={styles.desc}>
                {itemData.content.map((textItem, index) => {
                  return (
                    <div className={styles.descItem} key={index}>
                      <img src={img.pinkArrow} alt="" />
                      <p className={styles.fw500}>{textItem}</p>
                    </div>
                  )
                })}
              </div>
              {itemData.img && (
                <div
                  className={styles.img}
                  style={{ backgroundImage: `url(${itemData.img})` }}
                ></div>
              )}

              {itemData.url && (
                <Link to={itemData.url} itemProp="url" target="_blank">
                  <div className={styles.more}>
                    <span>查看更多</span>
                    <div className={styles.circle}>
                      <img
                        src={img.arrowSmall}
                        alt=""
                        className={styles.arrowSmall}
                      />
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      )
    default:
      return null
  }
}
export default IntroItem
